<template>
  <main class="site-main">
    <!--상단 타이틀 영역 s-->
    <top-title
        type="03"
        header="BMS 직원 방문 및 연락 요청"
        body="BMS 제품과 관련하여 직원의 방문 및 연락을 요청하실 수 있는 신청서비스입니다."
    />
    <!--상단 타이틀 영역 e-->

    <div class="container">

      <!--Breadcrumb 영역 s-->
      <div class="top-nav-container">
        <nav role="navigator">
          <ul class="breadcrumbs list-unstyled hide-sm">
            <li class="breadcrumb">BMS 직원 방문 및 연락 요청</li>
          </ul>
        </nav>
      </div>
      <!--Breadcrumb 영역 e-->

      <div class="section">
        <!--입력창 s-->
        <section id="bo_w">
          <p class="bo_w_p_top"><span class="bo_w_red">*</span>표시 항목은 필수입력 항목입니다.</p>
          <div class="write_divbox">
            <div class="bo_w_info write_div line">
              <label for="wr_product">제품<span class="bo_w_red">*</span></label>
              <select name="report_product" id="wr_product" class="form-control" v-model="productId">
                <option disabled selected value="">제품을 선택하세요.</option>
                <option
                    v-for="(product,index) in $store.state.menu.productMenu"
                    :key="index"
                    :value="product.key"
                    v-html="`${product.name}(${product.ingredientName})`">
                </option>
              </select>
            </div>

            <div class="bo_w_info write_div line">
              <label for="wr_method">방식<span class="bo_w_red">*</span></label>
              <select name="request_method" id="wr_method" class="form-control" v-model="howVisit">
                <option disabled selected value="">방식을 선택하세요.</option>
                <option value="직접방문">직접방문</option>
                <option value="전화">전화</option>
                <option value="이메일">이메일</option>
                <option value="화상 미팅">화상 미팅</option>
              </select>
            </div>

            <div class="bo_w_info write_div line select-date">
              <label for="wr_method">날짜<span class="bo_w_red">*</span></label>
              <datepicker
                  v-model="visitDate"
                  format="yyyy-MM-dd"
                  input-class="form-control"
                  placeholder="방문 및 연락 희망일을 선택하세요."
              >
              </datepicker>
            </div>

          </div>
          <div class="btn_area">
            <button class="btn_main" @click="sand">제출하기</button>
          </div>

          <div class="modal" v-if="submitModal">
            <!-- 사이트 이탈 Modal의 내용 -->
            <div class="modal_contents">
              <div class="modal_close close">
                <a @click="close">close</a>
              </div>
              <p class="modal_tit">선생님의 BMS 직원 방문 및 연락 요청<br> 신청이 접수 완료되었습니다.</p>
              <p class="modal_exp">접수된 내용을 바탕으로 빠른 시일 내에<br> 선생님께 연락드리도록 하겠습니다.</p>
              <p class="modal_exp">감사합니다.</p>
              <div class="modal_btn">
                <span class="btn_modal" @click="close">확 인</span>
              </div>
            </div>
          </div>
        </section>
        <!--입력창 e-->
      </div>
    </div>
  </main>
</template>

<script>
import TopTitle from "@/components/common/TopTitle";
import sendReportAPI from "@/api/sendReportAPI";
import Datepicker from 'vuejs-datepicker';

export default {
  name: 'RequestService.vue',
  components: {
    TopTitle,
    Datepicker
  },
  data() {
    return {
      submitModal: false,
      productId: '',
      howVisit: '',
      visitDate: '',
      menu: false,
    };
  },
  mounted() {
    if (this.$route.query['open-modal']) {
      this.$nextTick(() => {
        this.switchModal()
      })
    }
  },
  methods: {
    switchModal() {
      this.submitModal = !this.submitModal;
      if (this.submitModal) {
        document.documentElement.classList.add("overflow-y-hidden");
      } else {
        document.documentElement.classList.remove("overflow-y-hidden");
      }
    },
    validate() {
      if ((!this.productId && !this.howVisit && !this.visitDate)
          || (!this.productId && !this.howVisit)
          || (!this.productId && !this.visitDate)
          || (!this.howVisit && !this.visitDate)) {
        alert('제품, 방식, 날짜는 필수 입력 항목입니다. 입력하지 않은 항목이 있는지 확인해주세요.')
        return false
      }
      if (!this.productId) {
        alert('제품을 선택해주세요.')
        return false;
      }
      if (!this.howVisit) {
        alert('원하시는 방문 및 연락 요청 방식을 선택해 주세요.')
        return false;
      }
      if (!this.visitDate) {
        alert('원하시는 날짜를 선택해주세요.')
        return false;
      }
      if (this.$moment(this.visitDate).diff(this.$moment(), 'days') < 2) {
        alert('현재일로부터 2일(48시간) 이후의 날짜로 방문 및 연락 요청을 신청하실 수 있습니다.')
        return false;
      }
      return true;
    },
    async sand() {
      if (this.validate()) {
        await sendReportAPI.postVisitRequest({
          productId: this.productId,
          howVisit: this.howVisit,
          visitDate: this.visitDate,
        }).then(value => {
          const {data} = value
          console.log(data)
          if (data.code === 200) {
            this.switchModal()
          }
        }).catch(e => {
          console.log(e)
          throw e
        })
      }
    },
    close() {
      this.$router.go(0)
      this.switchModal()
    },
    getFormatDate(date) {
      const year = date.getFullYear();
      let month = (1 + date.getMonth());
      month = month >= 10 ? month : '0' + month;
      let day = date.getDate();
      day = day >= 10 ? day : '0' + day;
      return `${year}-${month}-${day}`
    }
  },
};
</script>
<style scoped>
</style>
